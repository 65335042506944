import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

// Styles
import "../../css/components/work.css"


const transition= {
  type: 'tween',
  duration: 0.2
}

const Work = ({ name, description, type, Image, margin }) => {
  return (
    <motion.div
      whileHover={{ translateY: -7 }}
      whileTap={{ translateY: -4 }}
      transition={transition}
      className={"bg-black text-white inline-flex flex-col work-card cursor-pointer " + margin}
    >
      <div className="p-8">
        <h6 className="mb-2 uppercase tracking-mid-wide text-xs font-bold">{name}</h6>
        <p className="text-white opacity-75 text-sm mb-0">{description}</p>
        <p className="link-text text-white text-sm pt-2 absolute">{ type === 'plugin' ? 'view plugin' : 'read case study'}</p>
      </div>
      {typeof Image === 'function' ? 
        <div className="h-full flex items-center justify-center">
          <Image/>
        </div>
        : 
        <div className="h-full flex items-center justify-center tikkle-work-image" />
      }
    </motion.div>
  )
}

Work.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  Image: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]).isRequired,
  margin: PropTypes.string
}

export default Work
